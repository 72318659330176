.home-menu {
    max-width: 720px;
    margin: auto;
    margin-top: 30px;
}

#homepage-logo {
    margin: 0 auto;
    display: block;
}

.home-create-room-button {
    display: block;
    background-color: var(--button-blue-color);

    border-width: 7px;
    border-style: solid;
    border-color: var(--button-blue-color);

    transition: background-color 0.1s ease-out, border-color 0.1s ease-out;

    font-family: "Coolvetica Rg", sans-serif;
    font-weight: 400;
    font-size: 4em;
    text-align: center;
    text-decoration: none;

    padding: 80px;
    border-radius: 80px;
}

.home-create-room-button:hover {
    color: inherit;

    /*background-color: var(--button-pink-color);*/

    /*border-color: var(--button-pink-color);*/
    border-color: white;
}

.home-join-room-form {
    margin-top: 100px;

    display: flex;
}

#home-join-room-input {
    flex: 1;

    font-size: 1.75em;

    text-transform: lowercase;

    padding: 12px 18px;
}

#home-join-room-input::placeholder {
    text-transform: none;
}

#home-join-room-button {
    margin-left: 20px;

    background-color: var(--button-blue-color);

    transition: background-color 0.1s ease-out;

    width: 100px;

    display: flex;
    align-items: center;
    justify-content: center;
}

#home-join-room-button:hover {
    background-color: var(--button-blue-color-lighter);
}

@media screen and (max-width: 1024px) {
    .home-create-room-button {
        font-size: 3em;
        padding: 55px;
        border-radius: 70px;
    }
}

@media screen and (max-width: 768px) {
    .home-create-room-button {
        font-size: 3em;
        padding: 50px;
        border-radius: 50px;
    }
}

@media screen and (max-width: 576px) {
    .home-create-room-button {
        font-size: 2em;
        padding: 30px;
    }

    .home-join-room-form {
        margin-top: 50px;

        flex-direction: column;
    }

    #home-join-room-input {
        font-size: 1.3em;
    }

    #home-join-room-button {
        margin-left: 0;
        margin-top: 10px;

        width: 100%;

        height: 60px;
    }
}

@media screen and (max-height: 768px) {
    .home-join-room-form {
        margin-top: 50px;
    }
}
