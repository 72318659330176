.settings-container {
    height: 100%;
    overflow: auto;

    display: flex;
    flex-direction: column;
}

.settings-title {
    text-align: center;
}

.settings-mobile-scrollable-container {
    height: 100%;

    overflow-y: scroll;
    padding-right: 10px;
}

.settings-data-container {
    height: 0;
    flex: 1;

    display: flex;
}

.settings-main-data {
    flex: 1;

    margin-left: 50px;
    margin-right: 50px;
}

.settings-desktop-menu-container,
.settings-desktop-menu-counterbalance {
    width: 280px;
    overflow: auto;
}

.settings-main-data,
.settings-desktop-menu-container,
.settings-desktop-menu-counterbalance {
    height: 100%;
}

.settings-desktop-menu-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100px;

    font-size: 1.4em;

    border: 4px solid #fff;
    border-radius: 20px;
}

.settings-desktop-menu-button:not(:first-child) {
    margin-top: 30px;
}

.settings-mobile-section-display-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 70px;

    font-size: 1.2em;

    margin-top: 20px;
    margin-bottom: 20px;

    border: 5px solid #fff;
    border-radius: 10px;
}

.settings-desktop-menu-button,
.settings-mobile-section-display-button {
    color: #fff;

    background-color: var(--element-primary-background-color);
    transition: background-color 0.1s ease-out;
}

.settings-desktop-menu-button.active,
.settings-mobile-section-display-button.active {
    background-color: var(--button-pink-color);
}

.settings-desktop-menu-button:hover,
.settings-mobile-section-display-button:hover {
    cursor: pointer;
}

.settings-desktop-menu-button:not(.active):hover,
.settings-mobile-section-display-button:not(.active):hover {
    background-color: var(--element-primary-background-color-lighter);
}

.settings-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.email-settings-form-container,
.password-settings-form-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

#email-settings-form,
#password-settings-form {
    flex: 1;
    max-width: 600px;
}

.settings-form-submit-button-container {
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 993px) {
    .settings-mobile-scrollable-container {
        display: flex;
        flex-direction: column;

        overflow-y: auto;
    }

    .settings-main-data {
        padding-left: 10px;
        padding-right: 10px;
    }

    .settings-mobile-section-display-button {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .settings-desktop-menu-container,
    .settings-desktop-menu-counterbalance {
        width: 230px;
    }
}

@media screen and (max-width: 992px) {
    .settings-data-container {
        flex-direction: column;

        height: auto;
    }

    .settings-main-data {
        flex: 1;

        margin-left: 0;
        margin-right: 0;
    }

    .settings-desktop-menu-container,
    .settings-desktop-menu-counterbalance {
        display: none;
    }
}
