.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.mt {
    margin-top: 0.75rem;
}

.mt2 {
    margin-top: 1.5rem;
}

.mt3 {
    margin-top: 2.25rem;
}

.mb {
    margin-bottom: 0.75rem;
}

.mb2 {
    margin-bottom: 1.5rem;
}

.mb3 {
    margin-bottom: 2.25rem;
}

.ml {
    margin-left: 0.75rem;
}

.ml2 {
    margin-left: 1.5rem;
}

.ml3 {
    margin-left: 2.25rem;
}

.mr {
    margin-right: 0.75rem;
}

.mr2 {
    margin-right: 1.5rem;
}

.mr3 {
    margin-right: 2.25rem;
}

h1, .h1 {
    font-family: 'Coolvetica Rg', sans-serif;
    font-size: 5em;
    font-weight: 400;
}

h2, .h2 {
    font-size: 2.1em;
    font-weight: 700;
}

h3, .h3 {
    font-size: 1.8em;
    font-weight: 700;
}

h4, .h4 {
    font-size: 1.6em;
}

h5, .h5 {
    font-size: 1.4em;
}

h6, .h6 {
    font-size: 1.2em;
}

h1.mt, .h1.mt,
h2.mt, .h2.mt,
h3.mt, .h3.mt,
h4.mt, .h4.mt,
h5.mt, .h5.mt,
h6.mt, .h6.mt {
    margin-top: 0.5em;
}

h1.mb, .h1.mb,
h2.mb, .h2.mb,
h3.mb, .h3.mb,
h4.mb, .h4.mb,
h5.mb, .h5.mb,
h6.mb, .h6.mb {
    margin-bottom: 0.5em;
}

.full-width {
    width: 100%;
}

.flex-container {
    display: flex;
}

.flex-container-column {
    display: flex;
    flex-direction: column;
}

.flex-item-full-space {
    flex: 1;
}

.zero-height {
    height: 0;
}

.float-right {
    float: right;
}

.clearfix {
    clear: both;
}

.visible {
    display: initial;
}

.hidden {
    display: none;
}

@media screen and (max-width: 992px),
       screen and (max-height: 768px) {
    h1, .h1 {
        font-size: 3em;
    }
}
