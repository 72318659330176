:root {
    --game-mode-border-color: #fff;

    --category-button-width: 250px;
    --category-button-horizontal-margin: 25px;
    --category-button-vertical-margin: 40px;
    --category-buttons-count-per-row: 4;
}

.create-game-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;
}


/** CHOOSE GAME MODE */

.game-mode-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;

    overflow-y: auto;
}

.game-mode {
    border: 3px solid var(--game-mode-border-color);
    border-radius: 30px;

    color: #fff;
    background-color: var(--element-primary-background-color);

    transition: background-color 0.15s ease-out;
    padding: 0;

    margin: 10px;

    overflow: hidden;

    position: relative;
}

.game-mode:not(.mobile) {
    height: 280px;
    width: 280px;
}

.game-mode.mobile {
    border: 3.5px solid #fff;
    border-radius: 15px;
    width: 400px;
    height: auto;
}

.game-mode-disabled-mobile {
    display: none;
}

.game-mode.mobile .game-mode-disabled-mobile {
    display: initial;
}

.game-mode-button-content {
    /*border: 3px solid red;*/

    transition: transform 0.15s ease-out;
}

.game-mode:not(.mobile) .game-mode-button-content {
    height: 100%;
}

.game-mode.mobile .game-mode-button-content {
    display: flex;
}

.game-mode:not(.mobile):hover .game-mode-button-content {
    transform: translateY(-80%);
}

.game-mode:not(.disabled):hover {
    cursor: pointer;

    /*background-color: #DC71C7;*/
}

.game-mode.disabled.disabled-animation {
    animation: game-mode-disabled-click 0.2s linear;
}

@keyframes game-mode-disabled-click {
    0% {
        transform: translateX(-10px);
    }

    20% {
        transform: translateX(8px);
    }

    40% {
        transform: translateX(-6px);
    }

    60% {
        transform: translateX(4px);
    }

    80% {
        transform: translateX(-2px);
    }

    100% {
        transform: translateX(0);
    }
}

.game-mode-label-mobile {
    font-size: 2rem;

    margin-top: 10px;

    display: none;
}

.game-mode.mobile .game-mode-label-mobile {
    display: block;
}

.game-mode-label-container {
    display: flex;
    flex-direction: column;

    height: 100%;
}

.game-mode.mobile .game-mode-label-container {
    align-self: center;
}

.game-mode-icon-container {
    height: 80%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.game-mode.mobile .game-mode-icon-container {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.game-mode-icon {
    max-width: 80%;
    max-height: 80%;
}

.game-mode.mobile .game-mode-icon {
    max-width: 90%;
    max-height: 90%;
}

.game-mode-label-desktop {
    /*border: 3px solid orange;*/

    height: 20%;

    text-align: center;
    font-size: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.game-mode.mobile .game-mode-label-desktop {
    display: none;
}

.game-mode-label-disabled-desktop {
    max-width: 30px;
    max-height: 30px;

    margin-left: 5px;
}

.game-mode.mobile .game-mode-label-disabled-desktop {
    display: none;
}

.game-mode-description-container {
    /*border: 3px solid palevioletred;*/

    height: 80%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.game-mode.mobile .game-mode-description-container {
    width: 100%;

    align-self: center;

    margin-bottom: 10px;
}

.game-mode-description {
    font-size: 1.1rem;
    padding-left: 15px;
    padding-right: 15px;
    /*margin-top: 10px;*/
}

.game-mode.mobile .game-mode-description {
    font-size: 1rem;

    text-align: left;
}

@media screen and (max-width: 375px) {
    .game-mode.mobile .game-mode-icon-container {
        width: 50px;
        height: 50px;
    }

    .game-mode.mobile .game-mode-description {
        font-size: 0.9rem;
    }
}


/** CHOOSE CATEGORIES */

.category-container {
    /*border: 3px solid red;*/

    width: calc(
        var(--category-button-width) * var(--category-buttons-count-per-row)
        +
        var(--category-button-horizontal-margin) * (var(--category-buttons-count-per-row) * 2 - 2)
        +
        70px
    );
    margin: auto;
}

.category-container-header {
    display: flex;
    margin-bottom: 10px;
}

.category-list {
    /*border: 3px solid orange;*/

    margin-top: 0;
    margin-bottom: 0;
    margin-left: calc(var(--category-button-horizontal-margin) * -1);
    margin-right: 0;

    overflow-y: auto;
    overflow-x: hidden;
}

.category {
    background-color: var(--element-primary-background-color);

    transition: background-color 0.1s ease-out;

    color: #fff;
}

.category-list .category {
    border: 3px solid var(--game-mode-border-color);
    border-radius: 30px;

    width: var(--category-button-width);

    font-size: 1.4em;

    margin: var(--category-button-vertical-margin) var(--category-button-horizontal-margin);
    padding: 25px 0;
}

.selected-categories-list .category {
    border: 2px solid var(--game-mode-border-color);
    border-radius: 10px;

    width: 120px;

    font-size: 0.82em;

    padding: 5px 0;

    margin-left: 30px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.category:hover {
    cursor: pointer;

    background-color: var(--element-primary-background-color-lighter);
}

.category.selected {
    background-color: var(--button-pink-color);
}

.category-list-no-category-found {
    margin: var(--category-button-vertical-margin) var(--category-button-horizontal-margin);
}

.pick-all-categories-button,
.unpick-all-categories-button {
    border: none;
    border-radius: 8px;
    padding: 8px;
    min-width: 50px;

    opacity: 1;
    transition: opacity 0.1s ease-out;

    display: flex;
    align-items: center;
    justify-content: center;
}

.pick-all-categories-button:not(:disabled):hover,
.unpick-all-categories-button:not(:disabled):hover {
    cursor: pointer;
}

.pick-all-categories-button:disabled,
.unpick-all-categories-button:disabled {
    opacity: 0.6;
}

.pick-all-categories-button {
    background-color: var(--button-green-color);
}

.unpick-all-categories-button {
    background-color: var(--button-red-color);

    margin-left: 15px;
}

.search-categories-input {
    margin-left: 15px;
    min-width: 250px;
    padding: 6px 12px;
}

.selected-categories-container {
    width: 300px;

    margin-top: 50px;
}

.selected-categories-list {
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;

    margin-left: -30px;
}

.create-game-footer {
    display: flex;
    margin-top: 40px;
    align-items: flex-end;
    justify-content: flex-end;
}

.create-game-footer.select-categories-create-game-footer {
    justify-content: space-between;
}

@media screen and (max-width: 1336px) {
    :root {
        --category-buttons-count-per-row: 3
    }
}

@media screen and (max-width: 1024px) {
    :root {
        --category-buttons-count-per-row: 2
    }

    .create-game-footer.select-categories-create-game-footer {
        justify-content: flex-end;
    }

    .create-game-footer-counterbalance,
    .selected-categories-container {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .category-container {
        width: 100%;
    }

    .category-list {
        margin: 0 -20px;
        padding-right: 50px;
    }

    .category-list .category {
        width: 100%;

        border: 3.5px solid #fff;
        border-radius: 15px;

        margin-top: 5px;
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 576px) {
    .category-container-header {
        flex-wrap: wrap;
    }

    .search-categories-input {
        margin-top: 10px;
        margin-left: 0;
    }

}

@media screen and (max-width: 375px) {
    .category-list {
        padding-right: 35px;
    }

    .category-list .category {
        padding: 15px 25px;
        font-size: 1.2em;
    }
}

@media screen and (max-height: 840px) {
    .create-game-footer-counterbalance,
    .selected-categories-container {
        display: none;
    }

    .create-game-footer.select-categories-create-game-footer {
        justify-content: flex-end;
    }
}

@media screen and (max-height: 768px) {
    .create-game-header {
        margin-bottom: 20px;
    }
}


/** CHOOSE OPTIONS */

.game-options-container {
    overflow-x: hidden;
    overflow-y: auto;
}

.game-options-container-main-row {
    width: 100%;
    display: flex;
    justify-content: center;
}

.game-options-main-row-block {
    border: 3px solid #fff;
    border-radius: 25px;
    padding: 25px;
    background-color: var(--element-primary-background-color);

    width: 450px;
}

.game-options-main-row-block:not(:first-child) {
    margin-left: 50px;
}

.game-options-main-row-block:not(:last-child) {
    margin-right: 50px;
}

.game-options-main-block-title {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 20px;
}

.game-options-mobile-title {
    display: none;
}

.game-options-container-secondary-row {
    display: flex;
    justify-content: space-evenly;
}

.question-type-wrapper {
    font-size: 1.2em;
}

.question-type-wrapper:not(:last-child) {
    margin-bottom: 20px;
}

.win-criterion-wrapper {
    display: flex;
    flex-direction: column;
}

.game-options-win-criterion-input-wrapper {
    display: inline-block;
    margin: auto;
    text-align: center;
}

.win-criterion-input {
    width: 80px;
}

.game-options-hardcore-questions-wrapper {
    font-size: 1.2em;
}

.game-options-mobile-section {
    display: none;
}

@media screen and (max-width: 992px) {
    .game-options-container-main-row,
    .game-options-container-secondary-row {
        display: block;
    }

    .game-options-main-row-block {
        border: none;
        background-color: transparent;
        border-radius: 0;

        padding-left: 0;
        padding-right: 0;

        width: auto;

        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .game-options-main-block-title {
        border: 5px solid #fff;
        background-color: var(--element-primary-background-color);
        border-radius: 10px;
        padding: 5px;
    }

    .game-options-mobile-title {
        display: block;

        margin-top: 8px;
    }

    .question-type-container {
        text-align: center;

        margin-left: -15px;
        margin-right: -15px;
    }

    .question-type-wrapper {
        display: inline-block;

        margin-left: 15px;
        margin-right: 15px;
    }

    .game-options-desktop-section {
        display: none;
    }

    .game-options-mobile-section {
        display: block;
    }
}
