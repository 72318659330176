#toast-container > .toast-info,
#toast-container > .toast-success,
#toast-container > .toast-warning,
#toast-container > .toast-error {
    background-image: none !important;
}

#toast-container > div {
    border-radius: 10px;
    padding: 15px 25px;
    width: 350px;

    animation-name: toast-show;
    animation-iteration-count: 1;
    animation-duration: 0.2s;
    animation-timing-function: ease-out;
}

@keyframes toast-show {
    from {
        opacity: 0;
        transform: scale(0.95) translateX(10%);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

#toast-container > div,
#toast-container > div:hover {
    opacity: 0.9;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
}

#toast-container > div:hover {
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
    cursor: default;
}

.toast-top-right {
    top: 60px;
}

.toast-success {
    background-color: #44c244;
}

.toast-info {
    background-color: #0f93b9;
}

.toast-warning {
    background-color: #f5a028;
}

.toast-error {
    background-color: #e0332b;
}
