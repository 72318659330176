:root {
    --app-header-height: 90px;
    --app-footer-height: 60px;

    --app-background-color: #23C5EF;

    --element-primary-background-color: #24BFF0;
    --element-primary-background-color-lighter: #3fcaf6;

    --button-blue-color: #40D9FF;
    --button-blue-color-lighter: #4fe3ff;

    --button-green-color: #14B981;

    --button-red-color: #DB2557;

    --button-pink-color: #DC71C7;
    --button-pink-color-lighter: #e898d8;

    --a-hover-color: #b1e7fa;
}

.app.mobile {
    --app-footer-height: 110px;
}

* {
    scrollbar-color: #FCFCFC #16AFD9;
}

::-webkit-scrollbar {
    border-right: 15px solid #16AFD9;
    width: 25px;
}

::-webkit-scrollbar-thumb {
    /*background-color: #FCFCFC;*/
    border-right: 15px solid #FCFCFC;
}

::-webkit-scrollbar-thumb:hover {
    border-right-color: #e6f5fa;
}

::-webkit-scrollbar-thumb:active {
    border-right-color: #d6f0f8;
}

@font-face {
    font-family: "Coolvetica Rg";
    src: url("/webfonts/Coolvetica-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Cooper Hewitt";
    src: url("/webfonts/CooperHewitt-Medium.woff2") format("woff2");
    font-weight: 400;
}

@font-face {
    font-family: "Cooper Hewitt";
    src: url("/webfonts/CooperHewitt-Semibold.woff2") format("woff2");
    font-weight: 500;
}

html,
body {
    height: 100%;
}

body {
    position: relative;

    background-color: var(--app-background-color);
    background-image: url("/img/background.png");
    background-repeat: repeat;
    /*min-height: 100vh;*/
    margin-bottom: calc(var(--app-footer-height) * -1);

    font-family: 'Cooper Hewitt', sans-serif;
    letter-spacing: 0.03em;
    color: white;
}

a {
    color: inherit;
    text-decoration: underline;

    transition: color 0.1s ease-out;
}

a.no-underline {
    text-decoration: none;
}

a:hover {
    color: var(--a-hover-color);
    cursor: pointer;
}

.app:not(.loading) {
    /*opacity: 1;*/
    /*transform: none;*/

    animation: loaded-app-fadein 0.6s cubic-bezier(.35,.85,.45,1);
}

@keyframes loaded-app-fadein {
    from {
        transform: scale(0.95);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.content-wrapper {
    height: calc(100% - var(--app-footer-height));
    font-size: 1.1em;
}

.app.no-footer .content-wrapper {
    height: 100%;
}

.app-header {
    padding: 20px;
    height: var(--app-header-height);

    display: flex;
    align-items: center;
}

.header-left {
    flex: 1;
    display: flex;
    align-items: center;

    height: 100%;
}

.header-right {
    display: flex;
    align-items: center;

    height: 100%;
}

.header-user-settings-cog {
    padding-left: 10px;
    font-size: 1.3em;
}

.header-user-settings-cog a {
    display: flex;
    align-self: center;
    align-items: center;
}

.header-user-settings-cog a svg {
    transition: transform 0.1s ease-out;
}

.header-user-settings-cog a:hover {
    color: inherit;
}

.header-user-settings-cog a:hover svg {
    transform: rotate(22.5deg);
}

.header-user-logout-button {
    padding-left: 10px;
    display: flex;
    align-self: center;
    align-items: center;
}

.logout-button:hover {
    cursor: pointer;
}

.logout-button-door {
    transform-origin: left center;
    transition: transform 0.1s ease-out;
}

.logout-button:hover .logout-button-door {
    transform: scale(0.9);
}

.logout-button .logout-button-arrow {
    transition: transform 0.1s ease-out;
}

.logout-button:hover .logout-button-arrow {
    transform: translateX(10%);
}

.app-loader {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    animation: app-loader-fadein 1s;
}

.app-loader > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes app-loader-fadein {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#root,
.app {
    height: 100%;
}

.app > footer {
    width: 100%;
    height: var(--app-footer-height);

    display: flex;
    align-items: center;
    justify-content: center;
}

.app.mobile > footer {
    flex-direction: column;
    justify-content: space-around;
}

.app.no-footer > footer {
    display: none;
}

.app > footer .footer-text-item {
    margin-left: 5px;
    margin-right: 5px;
}

.footer-feedback-button {
    font-size: 1rem;
    background-color: #fff;
    color: var(--element-primary-background-color);
    border: 2px solid #fff;
    border-radius: 1em;
    padding: 4px 25px;

    transition: background-color 0.1s ease-out, color 0.1s ease-out;
}

.footer-feedback-button:hover {
    cursor: pointer;
    background-color: var(--element-primary-background-color);
    color: #fff;
}

#page-content {
    margin-left: 40px;
    margin-right: 40px;

    height: calc(100% - var(--app-header-height));

    padding-bottom: 20px;
}

input, textarea {
    position: relative;

    border: 1px solid rgba(200, 200, 200, 0.3);
    padding: 12px;
    border-radius: 0;
    font-family: inherit;
    font-size: 1.1rem;
    color: #333;
    background-color: #fff;
    transition: background-color 0.1s ease-out;
}

input.error, textarea.error {
    color: #bb1717;
    background-color: #f0d3d3;
}

input::placeholder, textarea::placeholder {
    color: rgba(0, 0, 0, 0.4)
}

input:focus, textarea:focus {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    outline: none;
}

label.checkbox {
    position: relative;
}

input[type=checkbox] {
    width: 20px;
    height: 20px;
    opacity: 0;
}

label.checkbox > input[type=checkbox] {
    width: 0;
    height: 0;
}

label.checkbox > input + span {
    margin-left: 1.5em;
    user-select: none;
}

label.checkbox > input + span::before {
    content: '';
    width: 1.15em;
    height: 1.15em;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 1;

    border: 2px solid #fff;
    border-radius: 0;

    transition: background-color 0.1s ease-out;

    cursor: pointer;
}

label.checkbox > input:focus-visible + span::before {
    outline: auto;
    outline: -webkit-focus-ring-color 1px auto;
}

label.checkbox > input + span::after {
    content: '';
    width: 0.45em;
    height: 0.85em;
    position: absolute;
    top: 0.1em;
    left: 0.35em;
    border-bottom: 0.2em solid #2FB0DD;
    border-right: 0.2em solid #2FB0DD;
    border-bottom-right-radius: 0.2em;

    transform: rotate(40deg);

    cursor: pointer;

    opacity: 0;
    transition: opacity 0.1s ease-out;
}

label.checkbox > input:checked + span::before,
label.checkbox.checkbox-solid > input + span::before {
    background-color: #fff;
}

label.checkbox > input:checked + span::after {
    opacity: 1;
}

label.toggle {
    position: relative;
}

label.toggle > input[type=checkbox] {
    width: 0;
    height: 0;
}

label.toggle > input + span {
    margin-left: 2.8em;
    user-select: none;
}

label.toggle > input + span::before {
    content: '';
    width: calc(2.2em - 0.25em);
    height: 1.2em;
    position: absolute;
    top: 0;
    left: 0.25em;

    border: 0.2em solid #000;
    background-color: #d63a55;
    border-radius: 0.6em;

    cursor: pointer;
}

label.toggle > input:checked + span::before {
    background-color: #14B981;
}

label.toggle > input + span::after {
    content: '';
    width: 1.4em;
    height: 1.4em;
    position: absolute;
    top: -0.1em;
    left: 0;

    transition: left 0.1s ease-out;

    border: 0.2em solid #000;
    background-color: #fff;
    border-radius: 0.7em;

    cursor: pointer;
}

label.toggle > input:checked + span::after {
    left: 1em;
}

button {
    font-family: "Cooper Hewitt", sans-serif;
    letter-spacing: 0.5px;
}

#login-form,
#register-form,
#forgot-password-form,
#reset-password-form,
#contact-form {
    max-width: 460px;
    margin: auto;
}

.button {
    font-family: inherit;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.5px;

    padding: 15px 30px;

    border: none;
    border-radius: 20px;

    transition: background-color 0.1s ease-out;
}

.button.large {
    font-size: 1.4rem;
    padding: 20px 40px;
    border-radius: 25px;
}

.button.small {
    font-size: 1rem;
    padding: 10px 20px;
    border-radius: 15px;
}

.button:hover:not(:disabled) {
    cursor: pointer;
}

.button.green {
    background-color: #14B981;
    color: #f8f8f8;
}

.button.green:hover {
    background-color: #1ad093;
}

.button.red,
.quit-cross-button {
    background-color: #DB2557;
    color: #f8f8f8;
}

.button.red:hover,
.quit-cross-button:hover {
    background-color: #ee2c66;
}

.button:disabled {
    background-color: #999999 !important;
}

.quit-cross-button {
    border: 0;
    padding: 15px;
    line-height: 0;
    border-radius: 8px;

    transition: background-color 0.1s ease-out;
}

.quit-cross-button.responsive {
    transform-origin: top left;
    transform: scale(0.6) translateY(20px);
}

.quit-cross-button:hover {
    cursor: pointer;
}

#page-lateral-navigation {
    width: 260px;
    padding-right: 20px;
}

#page-lateral-navigation ul {
    width: 100%;
}

#page-lateral-navigation ul li {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
}

#page-lateral-navigation ul li a {
    display: inline-block;
    width: 100%;
    padding: 7px 15px;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.1s ease-out;
}

#page-lateral-navigation ul li a:hover {
    color: inherit;
    background-color: rgba(255, 255, 255, 0.075);
}

#page-lateral-navigation ul li.active a {
    background-color: rgba(255, 255, 255, 0.15);
}

#desktop-variable-content > section:not(.active) {
    display: none;
}

.back-arrow-button {
    background: none;
    border: none;
    cursor: pointer;

    display: flex;
    align-items: center;
}

.back-arrow-button svg {
    transform: scaleX(0.95);
    transform-origin: right;
    transition: transform ease-out 0.1s;
}

.back-arrow-button:hover svg {
    transform: scaleX(1);
}

.back-arrow-button:focus {
    outline: none;
}

.feedback-modal-app-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #00000088;
    z-index: 1;
}

.feedback-modal {
    position: absolute;
    bottom: 30px;
    right: 30px;

    height: 500px;
    width: 450px;

    background-color: var(--element-primary-background-color);
    border: 4px solid #fff;
    border-radius: 10px;
    padding: 30px;
}

.feedback-modal-close-cross-container {
    position: absolute;
    top: 30px;
    right: 30px;

    transform: scale(0.5);
    transform-origin: top right;
}

.feedback-modal-title {
    font-size: 1.6em;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
}

.feedback-modal-text {
    text-align: center;
    margin-bottom: 30px;
}

.feedback-modal-subject-input {
    width: 100%;
    margin-bottom: 20px;
}

.feedback-modal-message-textarea {
    width: 100%;
    margin-bottom: 20px;
    resize: none;
}

.feedback-modal-submit-button-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.lobby-hover-to-show-code {
    position: relative;
}

.lobby-hover-to-show-code .lobby-hidden-code-message,
.lobby-hover-to-show-code .lobby-room-code {
    display: inline-block;
    width: 100%;
    text-align: center;
    cursor: default;
}

.lobby-hover-to-show-code .lobby-hidden-code-message {
    font-size: 1.5em;
    padding-top: 30px;
    padding-bottom: 20px;

    transition: transform 0.1s;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
}

.lobby-hover-to-show-code .lobby-hidden-code-message.is-hover {
    transform: scale(0.95);
    transition: transform 0.5s ease-in;
}

.lobby-hover-to-show-code .lobby-room-code {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    padding-top: 20px;

    transition: opacity 0s;
}

.lobby-hover-to-show-code .lobby-room-code.visible {
    transition: opacity 0.2s ease-in;
}

.lobby-hidden-code-message:not(.visible),
.lobby-room-code:not(.visible) {
    opacity: 0;
}

.lobby-room-code {
    text-align: center;
    font-size: 2.4em;
}

.legal-notice-container {
    max-height: 100%;
    overflow-y: auto;
}

@media screen and (max-width: 576px),
       screen and (max-height: 640px) {
    .feedback-modal {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 0;
        border-radius: 0;
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-height: 768px) {
    :root {
        --app-header-height: 60px;
    }

    .app-header {
        padding: 5px 20px;
    }
}

@media screen and (min-width: 993px) {
    .quit-cross-button.responsive {
        display: none;
    }
}
